
import ApiService from "@/services/api.service"
import { AxiosResponse } from "axios"
import { defineComponent, onMounted, reactive, ref } from "vue"
import { formatMoney, formatDate } from '@/utils/Helpers'
import Datepicker from 'vue3-datepicker'

export interface ReportSalesWarehouse {
  date: string
  Ref: string
  client_name: string
  statut: string
  GrandTotal: number
  paid_amount: number
  due: number
  payment_status: string
}

export interface ResponseReportSalesWarehouse {
  totalRows: number
  sales: ReportSalesWarehouse[]
}

export default defineComponent({
  name: 'Sales',
  components: {
    Datepicker
  },
  setup(){
    const filter = reactive({
      limit: 100,
      SortField: 'date',
      SortType: 'asc',
      search: '',
    })
    const picked = ref(new Date())
    const rows = ref<ReportSalesWarehouse[]>([])

    const getSalesReport = async () => {
      try {
        const { data }: AxiosResponse<ResponseReportSalesWarehouse> = await ApiService.get('/report/Sales_Warehouse', {
          params: filter
        })
        rows.value = data.sales
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      getSalesReport()
    })
    
    return {
      filter,
      picked,
      rows,
      formatMoney,
      formatDate,
      getSalesReport
    }
  }
})
